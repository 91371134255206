@charset 'utf-8';

@import 'settings';
@import 'foundation';
//@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
//@include foundation-forms;
// @include foundation-range-input;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

//@include motion-ui-transitions;
//@include motion-ui-animations;

@import 'components/topbar';
@import 'components/dribbble';
@import 'global/typography';
@import 'global/spacing';

body {
  overflow-x: hidden;

}
.button {
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 0.7rem;
  padding:0.8rem 1.3rem 0.8rem 1.3rem;
}

.hero {
  background-color: $white;
  //background: url(/assets/img/home-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @include breakpoint(small) {
    height: 450px;
  }
  @include breakpoint(large) {
    height: 500px;
  }
  .hero-title {
    @include breakpoint(small) {
      padding-top: 140px;
      text-align: center;
    }
    @include breakpoint(large) {
      text-align: left;
    }
  }
  h1 {
    @include breakpoint(small) {
      font-size: 40px;
      text-align: center;
    }
    @include breakpoint(large) {
      font-size: 80px;
      text-align: left;
    }
  }
  h4 {
    @include breakpoint(small) {
      text-align: center;
    }
    @include breakpoint(large) {
      text-align: left;
    }
  }
}
li {
  list-style: square;
  line-height: 2rem;
}
a {
  color: $success-color;
}
.home-featured {
  a {
    opacity: 0.8;
    //filter: grayscale(100);
    transition: .5s ease;
    &:hover {
      opacity: 1;
      filter: grayscale(0);
    }
    h3 {
      font-size: 0.8rem;
    }
  }
}
.shadow {
  &:hover {
    box-shadow: 0px 10px 40px 5px rgba(0, 0, 0, 0.05);
    transition: 0.5s ease;
    transform: scale(1.008);
  }
  box-shadow: none;
  transition: 0.5s ease;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: auto;
  opacity: 0;
  transition: .5s ease;
  background-color: $black;
    .text {
    color: white;
    //font-size: 20px;
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-45%, -50%);
    -ms-transform: translate(-45%, -50%);
    transform: translate(-45%, -50%);
    text-align: center;
    &.sub {
      padding-top: 4rem;

    }
  }
}
.preview {
  position: relative;
  //width: 50%;
}
.preview:hover .overlay {
  opacity: 1;
}
.page-title {
  @include breakpoint(small) {
    background-color: $white;
    height: 10rem;
    text-align: center;
    h1 {
      padding-top: 3rem;
    }
  }
  @include breakpoint(medium) {
    height: 14rem;
    text-align: left;
    h1 {
      padding-top: 5rem;
    }
  }
}
.section {
  @include breakpoint(small) {
    padding:3rem 0 3rem 0;
  }
  @include breakpoint(medium) {
    padding:5rem 0 5rem 0;
    .column-content {
      padding-top: 3rem;
    }
  }
}
.full-width-image img {
  width: 100%;
}
.number-icon {
  padding-bottom: 1rem;
}
.next-project {
  background-color: $black;
  padding: 6rem 0 6rem 0;
  h2 {
    color: $white;
  }
}

.tease {
  height: 500px;
}
.support {
  background-image: url(/assets/img/support-cover@2x.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.contact {
  .menu.vertical {
    li {
      list-style: none;
      a {
        color: $black;
        //font-weight: 600;
        //text-transform: uppercase;
        font-size: 1rem;

      }
    }
  }
}
.footer {
  background: $white;
  .menu.vertical {
    li {
      list-style: none;
      a {
        color: $black;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.8rem;

      }
    }
  }
}
#totopbutton {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: $primary-color; /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 18px; /* Some padding */
  border-radius: 4px; /* Rounded corners */
  font-size: 14px; /* Increase font size */
  opacity: 0.8;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  @include breakpoint(small) {
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 20px; /* Place the button 30px from the right */
  }
  @include breakpoint(medium) {
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 20px; /* Place the button 30px from the right */
  }
}
