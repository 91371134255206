h1 {
  font-weight: 900;
  line-height: 1;
}
h3 {
  font-weight: 700;
}
h4 {
  font-weight: 200;
}
h5 {
  font-weight: 200;
}
h6 {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: $dark-gray;
}
.highlight {
  @include breakpoint(small) {
    font-weight: 200;
    font-size: 1.1rem;
  }
  @include breakpoint(medium) {
      font-size: 1.3rem;
  }
}
