.top-bar {
  .menu li {
    list-style: none;
    text-transform: uppercase;
    font-weight: 600;
    @include breakpoint(small) {
      a {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        color: $black;
      }
    }
    @include breakpoint(medium) {
      a {
        padding: 0.7rem 1.5rem;
      }
    }
  }
  .top-bar-left {
    @include breakpoint(small) {
      text-align: center;
      padding-bottom: 2rem;
    }
    @include breakpoint(medium) {
      text-align: left;
      padding-bottom: 0;
    }
  }
  .top-bar-right .menu {
    @include breakpoint(small) {
      text-align: center;
    }
    @include breakpoint(medium) {
      text-align: left;
    }
  }
}
